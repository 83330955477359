import React from 'react';
import PropTypes from 'prop-types';
import { Container, List } from './styled';

const PersonList = ({ children }) => (
  <Container>
    <List>
      { children }
    </List>
  </Container>
);

PersonList.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PersonList };