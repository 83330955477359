import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  max-width: 100%;  
`;

export const Container = styled.div`
  position: relative;

  // tweak to make it look nice on very small screens
  @media screen and (max-width: 340px) {
    margin-left: -1.5em;
  }
`;
