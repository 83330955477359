import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
import { Person } from "../components/Person";
import { PersonList } from "../components/PersonList";
import { FoundersList } from "../assets/founder/founders.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h1" components={components}>{`Hello, we're DCDC, nice to meet you!`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Who?`}</MDXTag>
      <MDXTag name="p" components={components}>{`DCDC is a cooperative body built with technology at its heart (a `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://platform.coop/",
          "title": "platform coop info"
        }}>{`platform coop`}</MDXTag>{`). We dare to dream of a future where software is created collaboratively and distributed frictionlessly, a future where tech companies operate fairly and transparently, and a future where technology empowers individuals, by protecting the privacy and self-sovereignity of their data.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Ok, so why a co-operative?`}</MDXTag>
      <MDXTag name="p" components={components}>{`The co-operative model allows us to take a fairer and more democratic approach to tech enterprise. We believe technology is for everyone, therefore the future of technology should be decided by everyone, not just a handful of elusive characters in Silicon Valley. Our direction is decided by a yearly-elected leadership team guided (by majority vote) by the input of our members, ofcourse membership is open to all and importantly all members have exactly one vote.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Cool, so what's the tech?`}</MDXTag>
      <MDXTag name="p" components={components}>{`At DCDC we utilise distributed ledger technology to store data securely and immutably and provide a resilient network for our platform. However DCDC's superpower is how its cooperatively-owned public blockchain is tied to its business model. This winning recipe allows us to remove the financial barriers often involved in launching decentralised apps, whilst simultaneously guaranteeing their quality for consumers. Anyone wanting to delve deeper technically can read our `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://blog.dcdc.io",
          "title": "technology blog"
        }}>{`tech/dev blog`}</MDXTag>{` or check out our `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "/developers",
          "title": "info for developers"
        }}>{`developer's page`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Anyway enough of the talky bit, it's time to meet the team...`}</MDXTag>
      <div>
  <PersonList>
  {FoundersList.founders.map(f => <Person person={f} />)}
  </PersonList>
      </div>    
           </MDXTag>;
  }

}
export const _frontmatter = {};
    