import styled from 'styled-components';

export const Card = styled.div `
  position: relative;
  background-color: #F3F3F3;
  overflow: hidden;        
  min-width: 280px;
  width: 300px;
  margin: 16px;
  color: black;
  padding: 25px;
  @media screen and(max-width: 479px) {
    background-color: #deaded;
  }
`;

export const Left = styled.div `
  float: left;
  max-width: 60%;
  margin-top: 0px;
  margin-left: -10px;
`;

export const Right = styled.div `
  float: right;
  max-height: 100%;
`;

export const MailIcon = styled.img `
  height: 20px;
  width: 33px;
  padding: 0px 6px 0px 0px;
  margin-top: 4px;
`;

export const LinkedIcon = styled.img `
  height: 20px;
  width: 41px;
  padding: 0px 6px;
  margin-top: 4px;
`;

export const TwitterIcon = styled.img `
  height: 20px;
  width: 34px;
  padding: 0px 6px;
  margin-top: 4px;
`;

export const Name = styled.div `
  color: black;
`;

export const Title = styled.div `
  color: gray;
`;

export const Job = styled.div `
  color: gray;
`;

export const InfoBox = styled.div `
  min-height: 100px;
  max-width: 150px;
`;

export const IconBox = styled.div `
  
`;

export const PhotoBox = styled.div `
  width: 120px;
  height: 168px;
  overflow: hidden;
  margin-right: -20px;
  margin-top: -20px;
`;

export const Photo = styled.img `
  width: 100%; 
  height: auto;
  padding-top: 20px; 
`;