import React from 'react';
import PropTypes from 'prop-types';
import twitterIcon from "../../assets/twitter.png";
import linkedInIcon from "../../assets/In.png";
import envelopeIcon from "../../assets/STOPLOOKINGATENVELOPES.png";
import { Left, Right, Name, Card, Title, Job, PhotoBox, Photo, InfoBox, IconBox, TwitterIcon, LinkedIcon, MailIcon} from './styled';

const Person = ({ person }) => (
  <Card>
    <Left>
      <InfoBox>
        <Name>{person["name"]}</Name>
        <Title>{person["title"]}</Title>
        <Job>{person["job"]}</Job> 
      </InfoBox>
      <IconBox>
        <a href={person["mailto"]} rel="nofollow">
          <MailIcon src={envelopeIcon} alt="mailto"></MailIcon>
        </a>   
        {person["twitter"] ? 
          <a href={person["twitter"]}>
            <TwitterIcon src={twitterIcon} alt="twitter"></TwitterIcon>
          </a>
        : null
        }         
        <a href={person["linkedin"]}>
          <LinkedIcon src={linkedInIcon} alt="linkedIn"></LinkedIcon>
        </a>
      </IconBox>          
    </Left>
    <Right>
      <PhotoBox>
        <Photo src={require(`../../assets/founder/photos/${person["photo"]}`)}></Photo>
      </PhotoBox>  
    </Right>
  </Card>
);

Person.propTypes = {
  person: PropTypes.object
};

Person.defaultProps = {
  person: {"name": "Joe Bloggs", "title": "Member", "job": "Blogger", "photo": "720_bw_ben.jpg"}
};

export { Person };