/*import { benPhoto } from './photos/benGray.png';
import { elmaPhoto } from './photos/elmaGray.png';
import { jamesPhoto } from './photos/jamesGray.png';
import { rhysPhoto } from './photos/rhysGray.png';
*/

const FoundersList = {
  randomise: function() {
    if(this.randArray.length === this.founders.length) { this.randArray = [] }
    let rand = Math.floor(Math.random() * 4);
    while(this.randArray.includes(rand)) {
      rand = Math.floor(Math.random() * 4);
    }
    this.randArray.push(rand);
    return this.founders[rand];
  },
  randArray: [],
  founders:  
    [
      {
        "name":"Benjamin Babik",
        "title":"Co-Founder",
        "job": "Technical Architect",
        "photo": "720_bw_ben.jpg",
        "linkedin":"https://www.linkedin.com/in/benjaminbabik/",
        "twitter":"https://twitter.com/ourben?lang=en",
        "mailto":"mailto:ben@dcdc.io"
      },
      {
        "name":"Elma Gakenyi",
        "title":"Co-Founder",
        "job": "Quality Assurance Engineer",
        "photo": "720_bw_elma.jpg",
        "linkedin":"www.linkedin.com/in/elma-gakenyi-38305622",    
        "twitter":"https://twitter.com/ElmaSlakovic?lang=en",
        "mailto":"mailto:elma@dcdc.io"
      },
      {
        "name":"James Turner",
        "title":"Co-Founder",
        "job": "Software Developer",
        "photo": "720_bw_james.jpg",
        "linkedin":"https://www.linkedin.com/in/james-turner-935377140/",
        "twitter":"",
        "mailto":"mailto:james@dcdc.io"
      },
      {
        "name":"Rhys Kyte",
        "title":"Co-Founder",
        "job": "Software Engineer",
        "photo": "720_bw_rhys.jpg",
        "linkedin":"https://www.linkedin.com/in/rhys-kyte/",
        "twitter":"",
        "mailto":"mailto:rhys@dcdc.io"
      }
    ]
  }    

export { FoundersList }
